import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffe35896"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-manager" }
const _hoisted_2 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FargoSearch = _resolveComponent("FargoSearch")!
  const _component_FargoTable = _resolveComponent("FargoTable")!
  const _component_checkDetail = _resolveComponent("checkDetail")!
  const _component_clientFile = _resolveComponent("clientFile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FargoSearch, {
      "search-list": _ctx.searchList,
      searchFormData: _ctx.searchFormData,
      "onUpdate:searchFormData": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchFormData) = $event)),
      onSearch: _ctx.search
    }, null, 8, ["search-list", "searchFormData", "onSearch"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.tableLoading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "account-export",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportDownload && _ctx.exportDownload(...args)))
          }, " 导出 "))
        : _createCommentVNode("", true),
      _createVNode(_component_FargoTable, {
        "table-loading": _ctx.tableLoading,
        limit: _ctx.limit,
        page: _ctx.page,
        list: _ctx.tableData,
        "onUpdate:list": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tableData) = $event)),
        "t-header-list": _ctx.tHeaderList,
        draggableTable: true,
        onSortTable: _ctx.sortTable,
        onChange: _ctx.change,
        onChangeSelect: _ctx.changeSelect
      }, null, 8, ["table-loading", "limit", "page", "list", "t-header-list", "onSortTable", "onChange", "onChangeSelect"])
    ]),
    _createVNode(_component_checkDetail, {
      dataForm: _ctx.dataForm,
      dialogVisible: _ctx.dialogVisible,
      "onUpdate:dialogVisible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event))
    }, null, 8, ["dataForm", "dialogVisible"]),
    _createVNode(_component_clientFile, {
      dataForm: _ctx.dataForm,
      dialogVisible: _ctx.dialogClientFileVisible,
      "onUpdate:dialogVisible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogClientFileVisible) = $event))
    }, null, 8, ["dataForm", "dialogVisible"])
  ]))
}