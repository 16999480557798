import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'

export default function useUpdateRemark () {
  const { mutate, onDone } = useMutation(gql.lpoaUpdateBindAccountsInfo)
  onDone(res => {
    const { lpoaUpdateBindAccountsInfo } = res.data
    if (lpoaUpdateBindAccountsInfo.ok) {
      ElMessage.success({
        message: '编辑备注成功',
        type: 'success',
        customClass: 'custom-success'
      })
    }
  })
  return {
    updateRemark: mutate
  }
}
