import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import { numFormat } from '@/utils/num_format'
import { useCommonInfo } from '@/hooks/common'

export default function useClientList() {
  const { moneyType, exchangeRate } = useCommonInfo()
  const tableLoading = ref(true)
  const tableData = ref()
  const downloadList = ref()
  const sortArr = ref() // 原始排序数组
  const sortArrEdit = ref() // 原始排序数组(备注更改)
  const { onResult, refetch } = useQuery(
    gql.lpoaClientList,
    {
      filter: {
        // fundAccount: state.account
      }
    },
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )
  onResult(res => {
    if (res.data) {
      const { lpoaClientList, lpoaManagersBindInfoList } = res.data
      downloadList.value = lpoaClientList.data.clients
      const mapUser = new Map()
      if (lpoaManagersBindInfoList.data) {
        // eslint-disable-next-line
        lpoaManagersBindInfoList?.data[0]?.bindInfo.forEach((v: any) => {
          mapUser.set(v.account, v)
        })
      }
      tableData.value = lpoaClientList.data.clients.map((v: any) => {
        let position = {}
        v.position = [...v.position, ...v.structuredFundPosition]
        const posiCount = v.position.length > 3 ? v.position.length : 3
        for (let index = 0; index < posiCount; index++) {
          const name = v.position[index]?.stockNameGb || '- -'
          const stockName = v.position[index]?.stockName || '- -'
          const code = v.position[index]?.stockCode || '- -'
          const exchangeType = v.position[index]?.exchangeType || '- -'
          const currentAmount = v.position[index]?.currentAmount || '- -'
          position = {
            ...position,
            [`name${index + 1}`]: exchangeType === 'FUND' ? stockName : name,
            [`code${index + 1}`]: exchangeType === 'FUND' ? '' : code,
            [`exchangeType${index + 1}`]: exchangeType,
            [`currentAmount${index + 1}`]: currentAmount,
            [`stockNameGb${index + 1}`]: exchangeType === 'FUND' ? stockName + currentAmount
              : name + code + exchangeType + currentAmount
          }
        }
        return {
          ...v,
          ...position,
          clientTypeText: mapUser.get(v.clientId).clientType === 10 ? '是' : '否',
          userId: mapUser.has(v.clientId)
            ? mapUser.get(v.clientId).userId
            : '- -',
          nameCn: mapUser.get(v.clientId)
            ? mapUser.get(v.clientId)?.nameCn
            : '', // 中文名
          input: mapUser.get(v.clientId) ? mapUser.get(v.clientId)?.remark : '', // 备注
          nameEn: mapUser.has(v.clientId)
            ? mapUser.get(v.clientId)?.englishFirstname +
            mapUser.get(v.clientId)?.englishLastname
            : '', // 英文名字
          assetRate:
            moneyType.value === 'HKD'
              ? numFormat(v.asset, 2)
              : numFormat(Number(v.asset) / Number(exchangeRate.value), 2),
          enableBalanceRate:
            moneyType.value === 'HKD'
              ? numFormat(v.enableBalance, 2)
              : numFormat(
                Number(v.enableBalance) / Number(exchangeRate.value),
                2
              ),
          // enableBalanceByMkt: v.fundinfo
          //   .filter((item: any) => item.exchangeType === moneyType)[0]
          //   ?.enableBalance.toLocaleString()
          enableBalanceHKD: v.fundinfo
            .filter((item: any) => item.moneyType === 'HKD')[0]
            ?.enableBalance.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
          enableBalanceUSD: v.fundinfo
            .filter((item: any) => item.moneyType === 'USD')[0]
            ?.enableBalance.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
          enableBalanceCNY: v.fundinfo
            .filter((item: any) => item.moneyType === 'CNY')[0]
            ?.enableBalance.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
        }
      })

      sortArr.value = tableData.value?.map((v: any) => ({
        tradeAccount: v.clientId
      }))
      sortArrEdit.value = tableData.value?.map((v: any) => ({
        tradeAccount: v.clientId,
        input: v.input
      }))
      console.log(tableData.value, '客户数据')
      tableLoading.value = false
    }
  })
  return {
    tableLoading,
    tableData,
    sortArr,
    downloadList,
    sortArrEdit,
    refetch
  }
}
