
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { tHeaderList, searchList } from './data'
import exportPng from '@/assets/images/home/export.png'
import checkDetail from './component/checkDetail.vue'
import { debounce } from 'lodash'
import * as gql from '@/graphql'
import { useMutation } from '@vue/apollo-composable'
import storage, { StorageType } from '@/utils/storage'
import useExport from './hooks/useExport'
import useUpdateRemark from './hooks/useUpdateRemark'
import useClientList from './hooks/useClientList'
import { useCommonInfo } from '@/hooks/common'
import clientFile from './component/clientFile.vue'
export default defineComponent({
  name: 'AccountManager',
  components: { checkDetail, clientFile },
  setup() {
    const state = reactive({
      limit: 10,
      page: 1,
      total: 100,
      tHeaderList: tHeaderList,
      searchList: searchList,
      searchFormData: {},
      dialogVisible: false,
      dialogClientFileVisible: false,
      dataForm: {},
      account: JSON.parse(storage.rcGetItem(StorageType.local, 'userInfo'))
    })
    const { moneyType } = useCommonInfo()
    state.tHeaderList.forEach((v: any) => {
      if (v.prop === 'assetRate') {
        v.label = `总资产(${moneyType.value})`
      }
      if (v.prop === 'enableBalanceRate') {
        v.label = `总购买力(${moneyType.value})`
      }
    })
    const { updateRemark } = useUpdateRemark()
    // 获取列表数据
    const { refetch, tableLoading, tableData, sortArr, sortArrEdit } =
      useClientList()
    const change = (data: { btnType: String, data: any }) => {
      switch (data.btnType) {
        case 'detail':
          state.dialogVisible = true
          state.dataForm = data.data
          break
        case 'editInput':
          if (data.data.input === '' || !data.data.input) return
          // 前后一致
          if (
            sortArrEdit.value?.filter(
              (v: any) => v.tradeAccount === data.data.clientId
            )[0].input === data.data.input
          ) {
            return
          }
          const params = {
            // manager: JSON.parse(
            //   storage.rcGetItem(StorageType.local, 'userInfo')
            // ), // 用户
            tradeAccount: data.data.clientId, // 编辑的账户
            tradeAccSource: 'ayers',
            remark: data.data.input // 编辑完成的内容
          }
          updateRemark({ params })
          break
        case 'clientFile':
          state.dialogClientFileVisible = true
          state.dataForm = data.data
          break
        default:
          break
      }
    }
    const changeSelect = (val: any) => {
      console.log(val)
    }
    // 获取列表数据
    const search = (type: any) => {
      switch (type) {
        case 'search':
          tableLoading.value = true
          const params = {
            filter: { ...state.searchFormData }
          }
          refetch(params)
          break
        case 'reset':
          tableLoading.value = true
          state.searchFormData = {}
          const params1 = {
            filter: {}
          }
          refetch(params1)
          break
        default:
          break
      }
    }
    // 导出
    const exportDownload = () => {
      useExport(tableData.value)
    }
    const isArrEqual = (arr1: any, arr2: any) => {
      return (
        arr1.map((v: any) => v.tradeAccount).join('') ===
        arr2.map((v: any) => v.tradeAccount).join('')
      )
    }
    // 排序
    const { mutate: sortFunc } = useMutation(gql.lpoaResortBindAccounts)
    // 拖动表格排序
    const sortTable = debounce(() => {
      // 排序的顺序
      const arr = tableData.value?.map((v: any) => ({
        tradeAccount: v.clientId,
        tradeAccSource: 'ayers'
      }))
      if (isArrEqual(arr, sortArr.value)) {
        return
      }
      const obj = {
        // manager: JSON.parse(storage.rcGetItem(StorageType.local, 'userInfo')),
        braccList: arr
      }
      console.log(obj)
      sortFunc({ params: obj }).then((result: any) => {
        const { lpoaResortBindAccounts } = result.data
        if (lpoaResortBindAccounts.ok) {
          ElMessage.success({
            message: '排序成功',
            type: 'success',
            customClass: 'custom-success'
          })
          refetch()
        }
      })
    }, 1500)
    return {
      ...toRefs(state),
      tableLoading,
      tableData,
      sortArr,
      sortArrEdit,
      change,
      search,
      changeSelect,
      exportDownload,
      sortTable,
      exportPng
    }
  }
})
