import { computed } from 'vue'
import { useStore } from 'vuex'

export const useCommonInfo = () => {
  const store = useStore()
  const commonInfo = computed(() => store.getters.getCommonInfo)
  const moneyType = computed(() => {
    //  缓存问题
    if (commonInfo.value.moneyType === 'HK') {
      return 'HKD'
    } else if (commonInfo.value.moneyType === 'US') {
      return 'USD'
    } else {
      return commonInfo.value.moneyType
    }
  })
  const exchangeRate = computed(
    () =>
      commonInfo.value.exchangeRate.filter(
        (item: { ccy: string }) => item.ccy === moneyType.value
      )[0].rate
  )

  return {
    moneyType,
    exchangeRate
  }
}
