export const searchList = [
  {
    label: '金马号 : ',
    model: 'matchstrNamegh',
    type: 'input',
    placeholder: '请输入金马号'
  },
  {
    label: '交易账号 : ',
    model: 'matchstrRemark',
    type: 'input',
    placeholder: '请输入交易账号'
  },
  // {
  //   label: '证件类型 : ',
  //   model: 'region',
  //   type: 'select',
  //   size: 'small',
  //   options: [
  //     {
  //       value: 0,
  //       label: '选项1'
  //     },
  //     {
  //       value: 1,
  //       label: '选项2'
  //     }
  //   ],
  //   placeholder: '-请选择-'
  // },
  {
    label: '英文名 : ',
    model: 'matchstrNameen',
    type: 'input',
    placeholder: '请输入英文名'
  },
  {
    label: '中文名 : ',
    model: 'matchstrNamezh',
    type: 'input',
    placeholder: '请输入中文名'
  },
  {
    type: 'setting',
    setting: [
      {
        name: '重置',
        btnType: 'reset',
        permission: [],
        size: 'small',
        class: 'reset-button'
      },
      {
        name: '查询',
        btnType: 'search',
        permission: [],
        size: 'small',
        class: 'search-button'
      }
    ]
  }
]
export const tHeaderList = Object.freeze([
  //   {
  //     type: 'selection',
  //     prop: 'selectStatus',
  //     width: '90',
  //     fixed: 'left',
  //     label: '全选'
  //   },
  // { label: '序列号', type: 'index', width: '80' },
  { label: '排序', type: 'sort', iconWidth: 14, iconHeight: 14, width: '80' },
  //   { label: '排序', type: 'sort', iconWidth: 30, iconHeight: 30, width: '80' },
  { label: '金马号', prop: 'userId', type: '', width: '120' },
  { label: '投资移民账户', prop: 'clientTypeText', type: '', width: '140' },
  { label: '交易账号', prop: 'clientId', type: '', width: '120' },
  { label: '中文名', prop: 'nameCn', type: '', width: '' },
  {
    label: '备注',
    prop: 'input',
    type: 'editInput',
    width: '150',
    editStatus: true,
    btnType: 'editInput',
    size: 'small'
  },
  { label: '英文名', prop: 'nameEn', type: '', width: '' },
  { label: '总资产(HKD)', prop: 'assetRate', type: '', width: '150' },
  { label: '总购买力(HKD)', prop: 'enableBalanceRate', type: '', width: '150' },
  {
    label: '市场购买力(HKD)',
    prop: 'enableBalanceHKD',
    type: '',
    width: '200'
  },
  {
    label: '市场购买力(USD)',
    prop: 'enableBalanceUSD',
    type: '',
    width: '150'
  },
  {
    label: '市场购买力(CNY)',
    prop: 'enableBalanceCNY',
    type: '',
    width: '150'
  },
  {
    label: '持仓1',
    prop: 'enName',
    type: 'twoLinesNews',
    width: '220px',
    propList: [
      { prop: 'name1', icon: 'exchangeType1', class: 'lines-stock-name' },
      { prop: 'code1', class: 'lines-stock-code' },
      { prop: 'exchangeType1', class: 'lines-stock-type' },
      { prop: 'currentAmount1', class: '' }
    ]
  },
  {
    label: '持仓2',
    prop: 'enName',
    type: 'twoLinesNews',
    width: '220px',
    propList: [
      { prop: 'name2', icon: 'exchangeType2', class: 'lines-stock-name' },
      { prop: 'code2', class: 'lines-stock-code' },
      { prop: 'exchangeType2', class: 'lines-stock-type' },
      { prop: 'currentAmount2', class: '' }
    ]
    // propList: [{ prop: 'stockNameGb2', icon: 'exchangeType2' }, { prop: 'currentAmount2', class: 'block-line', amount: 'amount' }]
  },
  {
    label: '持仓3',
    prop: 'enName',
    type: 'twoLinesNews',
    width: '220px',
    propList: [
      { prop: 'name3', icon: 'exchangeType3', class: 'lines-stock-name' },
      { prop: 'code3', class: 'lines-stock-code' },
      { prop: 'exchangeType3', class: 'lines-stock-type' },
      { prop: 'currentAmount3', class: '' }
    ]
    // propList: [{ prop: 'stockNameGb3', icon: 'exchangeType3' }, { prop: 'currentAmount3', class: 'block-line', amount: 'amount' }]
  },
  //   { label: '带icon', prop: 'name', type: 'icon', width: '', icon: 'HK' },
  //   {
  //     label: '下单数量',
  //     prop: 'num',
  //     type: 'addSub',
  //     width: '',
  //     iconWidth: 20,
  //     iconHeight: 20,
  //     iconColor: '#bfc5d6',
  //     btnTypeSub: 'sub',
  //     btnTypeAdd: 'add'
  //   },
  {
    label: '操作',
    fixed: 'right',
    type: 'setting',
    width: '120px',
    crossFlag: true,
    setting: [
      {
        name: '查看详情',
        btnType: 'detail',
        color: 'text',
        type: 'show', // 正常显示
        permission: []
      },
      {
        name: '客户文件',
        btnType: 'clientFile',
        color: 'text',
        type: 'show', // 正常显示
        permission: []
      }
      // {
      //   name: '编辑',
      //   btnType: 'edit',
      //   color: 'text',
      //   typeReal: 'status', // 显示控制的字段
      //   showValue: 0, // 具体字段显示的值
      //   permission: []
      // }
      //   {
      //     name: '查看',
      //     btnType: 'toView',
      //     color: 'text',
      //     type: 'show',
      //     permission: []
      //   },
      //   {
      //     name: '编辑',
      //     btnType: 'edit',
      //     color: 'text',
      //     type: 'show',
      //     permission: []
      //   },
      //   {
      //     name: '删除',
      //     btnType: 'delete',
      //     color: 'text',
      //     type: 'show',
      //     permission: []
      //   }
    ]
  }
  //   {
  //     label: '状态按钮',
  //     prop: 'name',
  //     type: 'button',
  //     width: '',
  //     btnType: '',
  //     color: 'success',
  //     size: 'small'
  //   }
])
// 详情页表格
export const tHeaderListDetail = Object.freeze([
  // { label: '代码', prop: 'stockCode', type: '', width: '' },
  // { label: '名称', prop: 'stockNameGb', type: '', width: '' },
  // { label: '总市值', prop: 'marketValue', type: '', width: '' },
  // { label: '可用', prop: 'enableAmount', type: 'permillage', width: '' },
  // { label: '持有', prop: 'currentAmount', type: 'permillage', width: '' },
  // { label: '成本', prop: 'costPriceN', type: '', width: '' },
  // { label: '现价', prop: 'currentPriceDisplayN', type: '', width: '' },
  // { label: '盈亏', prop: 'profit', type: 'twoLines', width: '', propList: [{ prop: 'profit' }, { prop: 'profitPercent' }] }
  { label: '代码', prop: 'stockCode', type: '', width: '120px' },
  { label: '名称', prop: 'stockNameGb', type: '', width: '120px' },
  { label: '总市值', prop: 'marketValueF', type: '', width: '120px' },
  { label: '可用', prop: 'enableAmount', type: '', width: '120px' },
  { label: '持有', prop: 'currentAmountN', type: '', width: '120px' },
  { label: '成本', prop: 'costPrice', type: '', width: '120px' },
  { label: '现价', prop: 'currentPriceDisplay', type: '', width: '120px' },
  {
    label: '盈亏',
    prop: 'profit',
    type: 'twoLines',
    width: '120px',
    propList: [{ prop: 'profit' }, { prop: 'profitPercent' }]
  },
  {
    label: '操作',
    fixed: 'right',
    type: 'setting',
    width: '120px',
    crossFlag: true,
    setting: [
      {
        name: '买入',
        btnType: 'buy',
        color: 'text',
        textColor: '#ffb400',
        type: 'show', // 正常显示
        permission: []
      },
      {
        name: '卖出',
        btnType: 'sell',
        color: 'text',
        textColor: '#386aff',
        type: 'show', // 正常显示
        permission: []
      }
    ]
  }
])

// 详情页基金持仓表格
export const tHeaderFundsListDetail = Object.freeze([
  {
    label: '名称',
    type: 'twoLines',
    width: '220px',
    propList: [
      { prop: 'stockName' }
    ]
  },
  { label: '数量', prop: 'currentAmountDisplay', type: '', width: '' },
  { label: '总市值', prop: 'marketValueDisplay', type: '', width: '' },
  { label: '持仓收益', prop: 'incomeBalanceDisplay', type: '', width: '' },
  { label: '持仓收益率', prop: 'incomePercentDisplay', type: '', width: '' }
])
