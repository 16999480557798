import { exportJson2Excel } from '@/utils/excel'
import { ElMessage } from 'element-plus'

export default function useExport (list: any) {
  let len = 3
  list.forEach((val: any) => {
    if (val.position.length > len) {
      len = val.position.length
    }
  })
  const tHeaderArr = []
  const filterValArr = []
  for (let index = 0; index < len; index++) {
    tHeaderArr.push(`持仓${index + 1}`)
    filterValArr.push(`stockNameGb${index + 1}`)
  }
  const tHeader = [
    '金马号',
    '客户账号',
    '中文名',
    '备注',
    '英文名',
    '总资产',
    '购买力',
    ...tHeaderArr
  ] // 表格title
  const filterVal = [
    'userId',
    'clientId',
    'nameCn',
    'input',
    'nameEn',
    'asset',
    'enableBalance',
    ...filterValArr
  ] // 对应的字段
  const formatJson = (filterKeys: any, jsonData: any) =>
    jsonData.map((data: any) =>
      filterKeys.map((key: string) => {
        return data[key]
      })
    )
  const data = formatJson(filterVal, list)
  exportJson2Excel(
    tHeader,
    data,
    '客户列表',
    undefined,
    undefined,
    true,
    'xlsx'
  )
  ElMessage.success({
    message: '导出成功',
    type: 'success',
    customClass: 'custom-success'
  })
}
